
@import url('https://fonts.googleapis.com/css2?family=Iceland&family=JetBrains+Mono:wght@400;700&display=swap');

:root {
  --font: 'JetBrains Mono', monospace;
  --fontNumber: 'Iceland', cursive;
  --colorBg: #131316;
  --colorBg1: #1C1C21;
  --colorBg2: #a6a6a60d;
  --color1: #F2F2F3;
  --color2: #8585AD;
  --colorCreate:  red;
  --colorPending: #00CCCC;
  --colorDone:    #F2F2F3;
}


* { padding: 0; margin: 0; box-sizing: border-box; }
html,body { -webkit-font-smoothing: antialiased; }
*::-webkit-scrollbar        { width: 8px; height: 8px; background-color: #2F2F37; }
*::-webkit-scrollbar-thumb  { border-radius: 1px; background: var(--color2); }

body {
  font-family: var(--font);
  background-color: var(--colorBg);
  color: var(--color1);
}

.error { color: red; }

a { transition: all .15s ease-in; }
a:hover { transition: all .15s ease-in; }



.app { min-height: 100vh; display: flex; flex-direction: column; }
.app .header        { background-color: #1C1C21; }
.app .main          { flex-grow: 1; width: 1240px; margin: 40px auto 0 auto; display: flex; gap: 80px; }
.app .main .center  { margin: auto; position: relative; top: -8vh; }
.app .main .double  { margin-top: 20px; }



.form     { text-align: center; margin-top: -10vh; color: var(--color2); max-width: 480px; }
.form  h1   { font-size: 24px; color: var(--color1); }
.form  a        { color: #4C4CFF; }
.form  a:hover  { text-decoration: none; }
.form .row          { margin-top: 24px; }
.form .row.submit   { margin-bottom: 80px; }
.form .note.list    { margin-top: 16px; }
.form .apiError     { margin-top: 1em; color: red; }
.form .apiError .link     { margin-top: 0.5em; font-size: 1.2em; }
.form .redirect     { color: greenyellow; }

.form.v2  { text-align: left; margin-top: 0; }
.form.v2 .row.submit   { text-align: right; }