
.modal {
    position: fixed;
    top:  50%;
    left: 50%;
    transform: translate(-50%,-52%);
    width: 720px;
    min-height: 540px;
    background-color: #000;
    box-shadow: 0 0 200px #8585AD;
    border-radius: 2em;
    padding: 40px;
    z-index: 100;
}
.modal .close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
}