
.overlay {
    background-color: rgba(0,0,0,0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

