.title  { font-size: 18px; font-weight: 700; color: #8585AD; margin-top: 2px; }
.img    { display: block; margin: 0 auto; min-height: 240px; }
.name   { font-size: 32px; font-weight: 700; text-align: center; margin: 16px 0 32px 0; }

.tth    { display: flex; gap: 5px; justify-content: center; color: #8585AD; max-width: 480px; margin: 0 auto; text-align: center; }
.tth > div      { flex-grow: 1; }
.tth > div .tname   { font-size: 16px; margin-bottom: 0.5em; }
.tth > div .tsum    { display: flex; gap: 0.5ch; justify-content: center; align-items: baseline; }
.tth > div .tsum .tval  { font-size: 28px; font-family: var(--fontNumber); }

.add    { text-align: center; margin-top: 2em; }
