.box    {
    display: block;
    color: inherit;
    text-decoration: none;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 40px;
    background-color: var(--colorBg1); 
    transition: all .25s ease-in;
}
.box:hover { background-color: #25252c; transition: all .25s ease-in; }

.head   { display: flex; justify-content: space-between; align-items: baseline; gap: 2em; }
.head .number   { font-size: 28px; font-family: var(--fontNumber); }
.head .number + div   { margin-right: auto; font-size: 18px; font-weight: bold; }
.head .time     { font-size: 16px; color: #8585AD; }

.name   { font-size: 24px; margin-top: 24px; }

.descr    { margin-top: 24px; display: flex; justify-content: space-between; align-items: center; }
.descr .sum     { display: flex; align-items: center; gap: 1ch; margin-top: -10px; }
.descr .sum .price  { font-size: 28px; font-family: var(--fontNumber); }

.createdColor   { color: red; }
.pendingColor   { color: #00CCCC; }
.doneColor      { color: inherit; }

.list {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 0 0 0 15px;
    padding: 0;
}
.border {
    position: relative;
    z-index: 3;
    overflow: hidden;
    margin-left: -15px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: solid 2px transparent;
    border-image-source: linear-gradient(63.18deg, #801AB3 0%, #4C4CFF 100%);
    background: linear-gradient(63.18deg, #801AB3 0%, #4C4CFF 100%), linear-gradient(0deg, #131316, #131316);

}
.border::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #131316;
    z-index: 1;
}
.border > img {
    display: block;
    height: 60px;
    margin-top: -59px;
    margin-left: -28px;
    position: absolute;
    z-index: 1;
}
.border > span {
    display: block;
    font-size: 16px;
    margin-top: -60px;
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 2;
}

